<template>
  <div>
    <v-container class="px-5 py-2">
      <v-row>
        <v-col class="d-flex align-center pb-1" cols=10>
          <span class="text modal-header-title"> {{title}} </span>
        </v-col>
        <v-col class="d-flex justify-end pb-1">
          <v-icon color="darken-1" @click="$emit('close-modal')">mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="px-n5"></v-divider>
    <v-container fluid class="content-wrapper px-5">
      <v-form class="add-project-form" @submit="submitForm">
        <v-row class="px-5">
          <v-col cols="12" class="pa-0">
            <BaseInput
                class="text-input mb-4"
                solo
                flat
                v-model.trim="subscription.name"
                inputLabel="Name"
                placeholder
                name="subscriptionName"
                :status="errors('name').length ? 'error': 'normal'"
                :error-messages="errors('name')"
                @input="$v.subscription.name.$touch()"
                @blur="$v.subscription.name.$touch()"
              />
          </v-col>
          <v-col cols="12" class="pa-0">
            <BaseInput
                class="text-input mb-4"
                solo
                flat
                v-model.trim="subscription.credits"
                inputLabel="Credits"
                placeholder
                name="subscriptionCredits"
                :status="errors('credits').length ? 'error': 'normal'"
                :error-messages="errors('credits')"
                @input="$v.subscription.credits.$touch()"
                @blur="$v.subscription.credits.$touch()"
              />
          </v-col>
          <v-col cols="12" class="pa-0">
            <BaseInput
                class="text-input mb-4"
                solo
                flat
                v-model.trim="subscription.cost"
                inputLabel="Cost"
                placeholder
                name="subscriptionCost"
                :status="errors('cost').length ? 'error': 'normal'"
                :error-messages="errors('cost')"
                @input="$v.subscription.cost.$touch()"
                @blur="$v.subscription.cost.$touch()"
              />
          </v-col>
          <v-col cols="12" class="pa-0">
            <BaseInput
                class="text-input mb-4"
                solo
                flat
                v-model.trim="subscription.access"
                inputLabel="Access"
                placeholder
                name="subscriptionaccess"
                :status="errors('access').length ? 'error': 'normal'"
                :error-messages="errors('access')"
                @input="$v.subscription.access.$touch()"
                @blur="$v.subscription.access.$touch()"
              />
          </v-col>
        </v-row>
        <v-row class="px-5">
          <v-col cols="12" class="d-flex justify-end py-2 px-0">
            <v-btn text
              @click="$emit('close-modal')"
              class="py-0"
              color="primaryGray1"
            >
              Close
            </v-btn>
            <v-btn color="info ml-2"
              type="submit"
              class="btn-purple"
              :loading="loading"
            >
              {{ action }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  maxLength, numeric, required,
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import BaseInput from '@/components/common/BaseInput';

export default {
  components: {
    BaseInput,
  },
  props: {
    subscriptionObj: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('profile', ['businessTypes']),
    title() {
      if (this.subscriptionObj.id) {
        return 'Edit Subscription';
      }
      return 'Add New Subscription';
    },
    action() {
      return this.subscriptionObj.id ? 'UPDATE' : 'ADD';
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.subscription[field].$dirty) return errors;
        switch (field) {
          case 'name':
          case 'access':
            if (!this.$v.subscription[field].required) { errors.push(`Please provide valid ${_.capitalize(field)}`); }
            break;
          case 'credits':
          case 'cost':
            if (!this.$v.subscription[field].required) { errors.push(`Please provide valid ${_.capitalize(field)}`); }
            if (!this.$v.subscription[field].numeric) { errors.push(`${_.capitalize(field)} must be numeric`); }
            if (!this.$v.subscription[field].maxLength) { errors.push(`${_.capitalize(field)} length should be max 5 digits`); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
  },
  data() {
    return ({
      subscription: {
        name: '',
        cost: null,
        credits: null,
        verticalId: null,
        access: '',
      },
      loading: false,
    });
  },
  methods: {
    ...mapActions('admin', ['addSubscriptionBlend', 'updateSubscriptionBlend']),
    async submitForm(e) {
      this.loading = true;
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let result;
        if (this.subscription.id) {
          result = await this.updateSubscriptionBlend(this.subscription);
        } else {
          result = await this.addSubscriptionBlend(this.subscription);
        }
        if (result.success) {
          this.$emit('close-modal');
        }
      }
      this.loading = false;
    },
  },
  validations: {
    subscription: {
      name: { required },
      cost: {
        required,
        numeric,
        maxLength: maxLength(5),
      },
      credits: {
        required,
        numeric,
        maxLength: maxLength(5),
      },
      access: { required },
    },
  },
  mounted() {
    if (this.subscriptionObj.id) {
      this.subscription = { ...this.subscriptionObj };
    }
  },
};
</script>

<style lang="scss" scoped>
  .header {
    position: relative;
    z-index: 2;
  }
  .form-body {
    max-height: 600px;
    overflow: auto;
  }
  ::v-deep .input-field {
    height: 40px;
  }
  ::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 40px !important;
    height: auto !important;
  }
  ::v-deep .v-input__slot {
    margin-bottom: 4px;
  }
  ::v-deep.text-input > .input-field > .v-input__control > .v-input__slot {
    box-shadow: none;
    border-radius: 2px;
    border: solid 1px $silver;
    background-color: #ffffff;
  }
</style>
