<template>
  <div class="main-container" style="height: 99%;">
    <v-row class="ma-0 mb-12">
      <v-col class="pa-0 d-flex align-center" lg="5">
        <pageTitle/>
      </v-col>
      <v-col class="d-flex align-center justify-end pa-0">
        <v-btn class="add-button font-family-2 btn-purple" @click="showForm = true"
          ><v-icon class="mr-2"> mdi-plus </v-icon> Add Subscription</v-btn
        >
      </v-col>
    </v-row>
    <template>
      <Loader v-if="loading" />
      <base-list v-else :headers="headers" :items="items" :fetching="loading" />
    </template>
    <v-container fluid v-if="showForm">
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="new-user-form"
        :modal="showForm"
        scrollable
      >
        <SubscriptionForm @close-modal="closeForm" :isAdmin="true" :subscriptionObj="selected" />
      </Modal>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import BtnCellRenderer from '@/components/projects/ProjectTeam/BtnCellRenderer';
import SubscriptionForm from '@/components/admin/SubscriptionForm';
import Modal from '@/components/common/Modal';
import Loader from '@/components/common/Loader';
import PageTitle from '@/components/common/PageTitle';
import BaseList from '../components/admin/common/BaseList';

export default {
  components: {
    BaseList,
    Modal,
    SubscriptionForm,
    Loader,
    PageTitle,
  },
  computed: {
    ...mapGetters('admin', ['subscriptionBlends']),
    headers() {
      const $this = this;
      const items = [
        $this.nameColumn(),
        'cost',
        'credits',
        $this.accessColumn(),
        $this.editColumn(),
      ];
      return items;
    },
    items() {
      return _.map(this.subscriptionBlends, (plan) => ({
        id: plan.id,
        name: plan.name,
        access: plan.access,
        cost: plan.cost,
        credits: plan.credits,
      }));
    },
  },
  data() {
    return {
      loading: false,
      selected: {},
      showForm: false,
    };
  },
  methods: {
    ...mapActions('admin', ['getSubscriptionBlends']),
    ...mapActions('profile', ['getClientOptions']),
    nameColumn() {
      return {
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate',
      };
    },
    accessColumn() {
      return {
        field: 'access',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
      };
    },
    businessVerticalColumn() {
      return {
        headerName: 'Business Vertical',
        field: 'Business Vertical',
        filter: true,
        tooltipField: 'Business Vertical',
        cellClass: 'notranslate',
        cellStyle: { textAlign: 'left' },
      };
    },
    editColumn() {
      const $this = this;
      return {
        headerName: 'Edit',
        field: 'id',
        cellClass: 'action pa-2 d-flex justify-center',
        cellRendererFramework: BtnCellRenderer,
        cellRendererParams: {
          image: 'edit',
          clicked(params) {
            $this.editClick(params.value);
            return false;
          },
        },
        width: 60,
      };
    },
    async editClick(id) {
      this.loading = true;
      this.selected = _.find(this.subscriptionBlends, { id });
      this.showForm = true;
      this.loading = false;
    },
    closeForm() {
      this.showForm = false;
      this.selected = {};
    },
  },
  async mounted() {
    this.loading = true;
    this.getClientOptions();
    await this.getSubscriptionBlends();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.add-button {
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}
</style>
